import { Link, animateScroll as scroll } from "react-scroll";

const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }

  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "")
    ),
    unique
  );
};

function ScrollSpyTabs(props) {
  const { tabsInScroll } = props;

  const itemsServer = tabsInScroll.map(tab => {
    const hash = textToHash(tab.text);
    return {
      icon: tab.icon || "",
      text: tab.text,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash)
    };
  });

  return (
    <>
      <div class="flex flex-col items-center justify-center mt-20" x-cloak x-data="appData()" x-init="appInit()">
        <div class="flex flex-col">
          <div class="fixed inset-x-0 top-0 z-50 h-0.5 mt-0.5 bg-fuchsia-200"></div>
          <nav class="flex justify-around py-4 bg-white/80
            backdrop-blur-md shadow-md w-full
            fixed top-0 left-0 right-0 z-10">
            <div class="flex items-center">
              <h1 className="text-3xl font-bold font-heading cursor-pointer font-serif" onClick={() => window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })}>
                <img class="h-10 object-cover" src="/favicon.ico" alt="Store Logo" />
              </h1>
            </div>
            <div class="flex items-center space-x-5">
              <ul className="flex justify-center flex-wrap px-4 mx-auto font-semibold font-heading">
                {itemsServer.map(navbarItem => (

                  <Link
                    className="pl-4 pt-1 hover:text-pink-300 hover:underline cursor-pointer"
                    to={navbarItem.hash}
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                  >
                    {navbarItem.text}
                  </Link>
                ))}

              </ul>
            </div>
          </nav>
        </div>
      </div>

      {itemsServer.map(item1 => (
        <article id={item1.hash} key={item1.text}>
          {item1.component}
        </article>
      ))}
    </>
  );
}

export default ScrollSpyTabs;

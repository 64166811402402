import React from 'react';
import Bellabeatproject from './projects/BellabeatProject'
import RMenuProject from './projects/RMenuProject'

const Projects = () => {
  return (
    <div id="projects" className="bg-gradient-to-b from-pink-100 from-10% via-fuchsia-100 via-30% to-gray-100 to-95%">
      <h1 className="pt-4 text-center text-xl text-gray-600 font-bold md:text-4xl underline">Projects</h1>
        <div className="flex flex-row flex-wrap justify-evenly">
          <Bellabeatproject />
          <RMenuProject />
        </div>
    </div>
  );
}

export default Projects;

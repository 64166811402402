import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// scroll to about me in 3 seconds
// const scrollToAboutMe = setInterval(function () {
//   if(window.scrollY < window.innerHeight) {
//     const aboutMe = document.getElementById('about-me');
//     const outOfService = document.getElementById('OOS');
//     if (outOfService) {
//       outOfService.scrollIntoView();
//     } else if(aboutMe) {
//       aboutMe.scrollIntoView();
//     }
//   }
//   return  clearInterval(scrollToAboutMe);
// }, 5000);
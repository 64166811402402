import AboutMe from './components/AboutMe'
import Timeline from './components/Timeline'
import Projects from './components/Projects'
import Footer from './components/Footer'
import ScrollSpyTabs from "./components/Navbar";

function App() {
  const OUT_OF_SERVICE = false;

  return (
    <div className="App">
      {
        OUT_OF_SERVICE ?
        <>
          <h1>Out of service</h1>
        </>
        :
        <>
          <ScrollSpyTabs
            tabsInScroll={[
              {
                text: "ABOUT ME",
                component: <AboutMe />
              },
              {
                text: "CV",
                component: <Timeline />
              },
              {
                text: "PROJECTS",
                component: <Projects />
              },
              {
                text: "CONTACT",
                component: <Footer />
              }
            ]}
          />
        </>
      }

    </div>
  );
}

export default App;

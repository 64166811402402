import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const TimeLine = () => {
  return (
    <div id="experience" className="pb-4 bg-gradient-to-t from-fuchsia-100 via-gray-50 to-fuchsia-100">
        <h1 className="pt-4 text-center text-xl text-gray-600 font-bold md:text-4xl underline">CV</h1>
        <VerticalTimeline>
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2023 - present"
            contentStyle={{ background: '#FFAC1C', color: 'black' }}
            iconStyle={{ background: '#f4f3ef', color: 'black' }}
            icon={<img src="https://rmenu.org/logo-plain.png" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">Finance & Data at <a target="_blank" href="https://rmenu.org" style={{color: 'white'}}>RMenu.org</a></h3>
            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
            <p className="description-timeline">
            Established and implemented comprehensive analytic tracking metrics for applications and user interactions,
            ensuring a systematic and data-driven approach to monitor user engagement, and performance metrics.
            Oversight of all financial transactions, maintaining a meticulous and organized record-keeping system to
            guarantee accuracy, compliance, and transparency in financial operations.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="08/2023 - present"
            contentStyle={{ background: '#f4f3ef', color: 'black' }}
            iconStyle={{ background: '#f4f3ef', color: 'black' }}
            icon={<img src="/cma.png" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">CMA Candidate</h3>
            <h4 className="vertical-timeline-element-subtitle">Institute of Management Accountants</h4>
            <p className="description-timeline">
              External Financial Reporting Decisions, Planning, Budgeting, and Forecasting, Performance Management, Cost Management, Internal Controls, Technology and Analytics,  Financial Statement Analysis, Corporate Finance, Decision Analysis, Risk Management, Investment Decisions, Professional Ethics
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="(Jun - Nov) 2021"
            contentStyle={{ background: '#FFAC1C', color: 'black' }}
            iconStyle={{ background: '#f4f3ef', color: 'black' }}
            icon={<img src="/google_cert.png" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">Google Data Analytics Certificate</h3>
            <h4 className="vertical-timeline-element-subtitle">Online / Coursera</h4>
            <p className="description-timeline">
              Data Aggregation, Data Analytics, Data calculations, Data Cleaning, Data Ethics, Data Visualization, Presentations, Problem Solving, R, Spreadsheets, SQL, Structural Thinking
            </p>
            <a target="_blank" style={{ color: 'white' }} href="https://www.credly.com/badges/df23c21c-1345-4b37-a031-c9e0fae7bedf?source=linked_in_profile"><p>Certificate</p></a>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#f4f3ef', color: 'black' }}
            date="2018 - present"
            iconStyle={{ background: 'rgb(167, 192, 157)', color: '#fff' }}
            icon={<img src="/fr_logo.jpeg" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">Senior Banker at First Republic Bank </h3>
            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
            <p className="description-timeline">
              Open personal and business accounts; facilitate transfers and wires. Review clients’
              financial statements to forecast anticipated account activity and find best product.
              Perform daily personal and office balancing. Monitor accounts with overdraft activity and
              payments with insufficient funds. Provide reports on client portfolios. Performing analysis
              for suspicious activity, maintaining KYC records.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016 – 2017"
            contentStyle={{ background: '#FFAC1C', color: 'black' }}
            iconStyle={{ background: 'rgb(167, 192, 157)', color: '#fff' }}
            icon={<img src="/asb_leasing_logo.png" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">Senior Business Leasing Specialist at ASB Leasing</h3>
            <h4 className="vertical-timeline-element-subtitle">Minsk, Belarus</h4>
            <p className="description-timeline">
              Based on performance, advanced to support clients in fulfilling financial obligation via personal and
              business financial strategies. Analyzed lessee’s financial statements, solvency calculations, and financial
              condition reports. Created client financial analysis reports based on quarterly statements. Prepared delivery
              contracts. Guided clients on completing forecasting/budgeting forms. Managed past-due payments. Created leasing
              transaction profitability analysis reports.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2015 – 2016"
            contentStyle={{ background: '#f4f3ef', color: 'black' }}
            iconStyle={{ background: 'rgb(167, 192, 157)', color: '#fff' }}
            icon={<img src="/belarusbank_logo.png" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">Business Lending Analyst at Belarusbank</h3>
            <h4 className="vertical-timeline-element-subtitle">Minsk, Belarus</h4>
            <p className="description-timeline">
              Analyzis of customers’ financial statements, solvency, and credit worthiness to mitigate risk and
              enable sound decision making. Prepared closing remarks concerning advisability of loan approvals,
              bank guarantees. Created client financial analysis reports based on quarterly statements. Prepared contracts.
              Produced interest accrual calculations.
            </p>
            <p className="description-timeline"><i>* <strong>Additional Experience: </strong>JSC Savings Bank Belarusbank, Retail Specialist (2014 – 2015)</i></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2011 – 2015"
            contentStyle={{ background: '#FFAC1C', color: 'black' }}
            iconStyle={{ background: 'rgb(167, 192, 157)', color: '#fff' }}
            icon={<img src="/bseu_logo.jpeg" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />}
          >
            <h3 className="vertical-timeline-element-title">Belarusian State Economic University</h3>
            <h4 className="vertical-timeline-element-subtitle">Minsk, Belarus</h4>
            <p className="description-timeline">
              Major in “Finance and Credit”. Gained comprehensive knowledge in various areas of
              financial and credit relations, and practical skills of financial reporting, as well as developed
              the ability of analytical thinking and creative approach to solving financial tasks. Furthermore,
              got up-to-date knowledge on financial management, financial institutions, fiscal relations,
              acquired skills in the organization of finance for private and public enterprises, learned the specifics
              of the government financial management.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: '#FFAC1C', color: '#fff' }}
          />
        </VerticalTimeline>
    </div>
  );
}

export default TimeLine;


import React from 'react';

const AboutMe = () => {
  return (
    <div id="about-me">
      <div className="absolute -inset-1 bg-gradient-to-r from-gray-400 to-pink-400 rounded-lg blur opacity-10"></div>
        <div className="py-16 bg-gray-50">
          <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
              <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                <div className="md:5/12 lg:w-5/12">
                  <img className="rounded-lg" alt="profile img" src="/profile.jpeg" loading="lazy" width="" height=""/>
                </div>
                <div className="md:7/12 lg:w-6/12">
                  <h2 className="text-xl text-gray-900 font-bold md:text-4xl">Hello! My name is Valeryia.</h2>
                  <p className="mt-6  text-gray-900">I enjoy analyzing financial data to evaluate risks, discover trends, and answer key questions along the way. My interest in finance and data analysis has been growing since I started to work in Banking as a lending analyst.</p>
                  <p className="mt-6 text-gray-900">Since then I’ve had the privilege of working in 2 Banks and a Leasing company. My main focus these days is to help companies make informed business decisions.</p>
                  <p className="mt-6 text-gray-900">Additionally, I have obtained a Google Data Analytics certificate, which has equipped me with valuable skills to enhance my data analysis capabilities.</p>
                  <p className="mt-4 text-gray-900">Here are a few tools and platforms I’ve been working with:</p>
                  <p><i>• Spreadsheets (Google Sheets or Microsoft Excel)</i></p>
                  <p><i>• SQL</i></p>
                  <p><i>• Tableau</i></p>
                  <p><i>• Rstudio</i></p>
                  <p><i>• Kaggle</i></p>
                </div>
              </div>
          </div>
        </div>
    </div>
  );
}

export default AboutMe;
